const STATIONS = [
  { name: "Faret bru, Kvinesdal", lng: 6.9601094, lat: 58.311671 },
  { name: "Nesbrua, Kvinesdal", lng: 6.96079423, lat: 58.31540965 },
  { name: "Åmodt bru, Kvinesdal", lng: 6.959572, lat: 58.318309 },
  { name: "Lona bru, Kvinesdal", lng: 6.82643525, lat: 58.31424074 },
  {
    name: "Kongevold bru, Flekkefjord",
    lng: 6.83906666,
    lat: 58.37839026,
  },
  { name: "Årliåna, Kvinesdal", lng: 7.03974959, lat: 58.33916491 },
  { name: "Sløåna, Kvinesdal", lng: 6.9351956, lat: 58.52311612 },
  {
    name: "Røynestad bru, Kvinesdal",
    lng: 6.95174311,
    lat: 58.38118074,
  },
  { name: "Boen bru, Kristiansand", lng: 8.14005539, lat: 58.2464052 },
  { name: "Nyland vest, Kvinesdal", lng: 6.92869357, lat: 58.41073734 },
  { name: "Steindør, Kvinesdal", lng: 7.0258625, lat: 58.3719983 },
  {
    name: "Grisefjorden, Flekkefjord",
    lng: 6.66228039,
    lat: 58.29835853,
  },
  { name: "Verven, Flekkefjord", lng: 6.66199101, lat: 58.29414654 },
  { name: "Lindland, Kvinesdal", lng: 6.90887438, lat: 58.55962751 },
  { name: "Eik bru, Lund", lng: 6.49521811, lat: 58.52510709 },
  { name: "Feda bru, Kvinesdal", lng: 6.81509007, lat: 58.27087454 },
  {
    name: "Ausdalstjørni, Kvinesdal",
    lng: 6.91523818,
    lat: 58.68332484,
  },
  { name: "Fåsdal, Flekkefjord", lng: 6.74193923, lat: 58.43321146 },
  {
    name: "Halsåvassbekk, Flekkefjord",
    lng: 6.62872956,
    lat: 58.27794648,
  },
  {
    name: "Nedre Kvinlaug, Kvinesdal",
    lng: 6.92873278,
    lat: 58.47797671,
  },
  {
    name: "Netlandsnes bru, Kvinesdal",
    lng: 6.91323914,
    lat: 58.62475869,
  },
  {
    name: "Narvestad bru, Kvinesdal",
    lng: 6.91680669,
    lat: 58.44456772,
  },
  {
    name: "Stakkeland, Kvinesdal",
    lng: 6.91531084,
    lat: 58.5175476
  },
  {
    name: "Kjelland bru, Kristiansand",
    lng: 7.76647736,
    lat: 58.09515164,
  },
  {
    name: "Stokkåna bru, Kristiandsand",
    lng: 7.78885489,
    lat: 58.20159246,
  },
  {
    name: "Tofteland bru, Kristiansand",
    lng: 7.82423648,
    lat: 58.09912382,
  },
  {
    name: "Åsen bru, Kristiansand",
    lng: 7.76468394,
    lat: 58.22689035
  },
  {
    name: "Timrevik bru, Lund",
    lng: 6.5466169,
    lat: 58.4579444
  },
];

export { STATIONS };
