import ReactDOM from 'react-dom';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { Rechart } from './';
import { STATIONS } from '../data';


const addStations = (map) => {
  STATIONS.forEach(station => {
    const coordinates = [station.lng, station.lat];

    const mapCardNode = document.createElement("div");

    mapCardNode.className = 'station';

    ReactDOM.render(
      <Rechart text={station.name} /> ,
      mapCardNode
    );

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: 'bottom',
    });

    popup.setDOMContent(mapCardNode);
    const marker = new mapboxgl.Marker({
      color: 'rgba(0, 0, 0, 0.6)',
      scale: 3,
      draggable: false,
      pitchAlignment: 'auto',
      rotationAlignment: 'auto',
    })
      .setLngLat(coordinates)
      .setPopup(popup)
      .addTo(map)
      .togglePopup();
  });
}

export { addStations };
