import { useState, useEffect } from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const min = 1;
const max = 3;

const shuffle = array => array.map(a => ({ sort: Math.random(), value: a })).sort((a, b) => a.sort - b.sort).map(a => a.value)

const defaultData = [
  { value: 2400 },
  { value: 1398 },
  { value: 9800 },
  { value: 3908 },
  { value: 4800 },
  { value: 3800 },
  { value: 4300 },
];

const redColor = 'rgba(255, 35, 114, 1)';
const blueColor = 'rgba(0, 102, 255, 1)';

const Rechart = ({ text }) => {
  const [data, setData] = useState(defaultData);
  const [percentage, setPercentage] = useState(80);
  const [color1, setColor1] = useState(redColor);
  const [color2, setColor2] = useState(blueColor);
  const [level, setLevel] = useState(2.40);

  useEffect(() => {
    const interval = setInterval(() => {
      const newData = shuffle(data);

      const values = newData.map((i) => i.value);
      const value = values.slice(values.length -1) * .001;

      setData(shuffle(newData));

      const perc = Math.floor(Math.random() * 90) + 20;

      setPercentage(perc);

      if (value >= 5000) {
        setColor1(redColor);
        setColor2(blueColor);
      } else {
        setColor1(blueColor);
        setColor2(redColor);
      }

      setLevel(value.toFixed(2));
    }, 3000);

    return () => clearInterval(interval);
  }, [data, percentage, color1, color2]);

  const levelText = `Latest: ${level} m`;

  return (
    <>
      <h2>{text}</h2>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={300} height={100} data={data}>
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
              <stop offset="0%" stopColor={color1} />
              <stop offset={`${percentage}%`} stopColor={color1} />
              <stop offset={`${percentage}%`} stopColor={color2} />
              <stop offset="100%" stopColor={color2} />
          </linearGradient>
          </defs>
          <Line type="monotone" dataKey="value" stroke="url(#gradient)" strokeWidth={8} dot={false}  />
        </LineChart>
      </ResponsiveContainer>
      <p>{levelText}</p>
    </>
  );
}

export { Rechart };
