import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import * as turf from '@turf/turf';
import { isPointWithinRadius } from 'geolib';

import { RIVER_LINES } from '../data';

const addLines = (map) => {
  const pinRoute = RIVER_LINES.features[0].geometry.coordinates;

  const popup = new mapboxgl.Popup({ closeButton: false });
  const marker = new mapboxgl.Marker({
    color: 'rgba(0, 102, 255, 0.6)',
    scale: 1,
    draggable: false,
    pitchAlignment: 'auto',
    rotationAlignment: 'auto',
  })
    .setLngLat(pinRoute[0])
    .setPopup(popup)
    .addTo(map)
    .togglePopup();

  map.addSource('line', {
    type: 'geojson',
    lineMetrics: true,
    data: RIVER_LINES,
  });

  map.addLayer({
    type: 'line',
    source: 'line',
    id: 'line',
    paint: {
      'line-color': 'rgba(0,0,0,0)',
      'line-width': 30,
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
  });

  map.once('idle', () => {
    const animationDuration = 300000;
    const path = turf.lineString(pinRoute);
    const pathDistance = turf.lineDistance(path);
    let start;

    const drawLines = (time) => {
      if (!start) {
        start = time;
      }

      const animationPhase = (time - start) / animationDuration;
      if (animationPhase > 1) {
        start = time;
        window.requestAnimationFrame(drawLines);
      }

      const alongPath = turf.along(path, pathDistance * animationPhase)
        .geometry.coordinates;

      const lngLat = {
        lng: alongPath[0],
        lat: alongPath[1]
      };

      // const radiusCheck = isPointWithinRadius(
      //   { latitude: lngLat.lat, longitude: lngLat.lng },
      //   { latitude: 58.68332484, longitude: 6.91523818 },
      //   100,
      // );

      map.panTo(lngLat);

      const elevation = Math.floor(
      map.queryTerrainElevation(lngLat, { exaggerated: false }));

      // popup.setHTML('Water Level: ' + elevation + 'm<br/>');
      marker.setLngLat(lngLat);

      map.setPaintProperty(
        'line',
        'line-gradient', [
          'step',
          ['line-progress'],
          'rgba(0, 102, 255, 0.6)',
          animationPhase,
          'rgba(0, 0, 0, 0)',
        ]
      );

      window.requestAnimationFrame(drawLines);
    }

    window.requestAnimationFrame(drawLines);
  });
};

export { addLines };
