import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { Graph } from "@intoto-dev/bibliotheca-graph";
import { DateTime } from "luxon";
import logo from "./intoto-logo.svg";

import { addStations, addLines, playCamera, Helpers } from "./lib";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2NvdHRiYXNnYWFyZCIsImEiOiJjanJsZDUyZ2IwNnJrNDVwancyOWpreWU1In0.McIem0uKcvkuLe5CTn9NbQ";

const MAP_STYLE = "mapbox://styles/scottbasgaard/ckykoq0u90jmk14nvaj6b61ob";
const LONGITUDE = 6.9244;
const LATITUDE = 58.69;
const ZOOM = 13.55;
const BEARING = 0;
const PITCH = 67.0;

const App = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(LONGITUDE);
  const [lat, setLat] = useState(LATITUDE);
  const [zoom, setZoom] = useState(ZOOM);
  const [bearing, setBearing] = useState(BEARING);
  const [pitch, setPitch] = useState(PITCH);

  const moveMap = (map) => {
    setLng(map.getCenter().lng.toFixed(4));
    setLat(map.getCenter().lat.toFixed(4));
    setZoom(map.getZoom().toFixed(2));
    setBearing(map.getBearing().toFixed(2));
    setPitch(map.getPitch().toFixed(2));
  };

  useEffect(() => {
    if (map.current) return;

    const config = {
      container: mapContainer.current,
      style: MAP_STYLE,
      interactive: false,
      hash: false,
      antialias: true,
      center: [lng, lat],
      zoom,
      bearing,
      pitch,
    };

    map.current = new mapboxgl.Map(config);
  });

  useEffect(() => {
    if (!map.current) return;

    map.current.on("move", () => {
      moveMap(map.current);
    });

    map.current.on("load", () => {
      Helpers.addFog(map.current);
      Helpers.addSky(map.current);
      Helpers.addElevation(map.current);

      addStations(map.current);
      addLines(map.current);
    });

    // playCamera(map.current);
  });

  const lineData = [
    {
      predicted: true,
      value: -1,
      date: "2021-11-07T23:00:00.000Z",
      minValue: -4,
      maxValue: 1,
    },
    {
      predicted: true,
      value: -4,
      date: "2021-11-07T17:00:00.000Z",
      minValue: -7,
      maxValue: -3,
    },
    {
      predicted: true,
      value: -4,
      date: "2021-11-07T11:00:00.000Z",
      minValue: -6,
      maxValue: -2,
    },
    {
      predicted: true,
      value: -4,
      date: "2021-11-07T05:00:00.000Z",
      minValue: -7,
      maxValue: -2,
    },
    {
      predicted: true,
      value: -6,
      date: "2021-11-06T23:00:00.000Z",
      minValue: -8,
      maxValue: -5,
    },
    {
      predicted: true,
      value: -3,
      date: "2021-11-06T17:00:00.000Z",
      minValue: -5,
      maxValue: -1,
    },
    {
      predicted: true,
      value: -3,
      date: "2021-11-06T11:00:00.000Z",
      minValue: -6,
      maxValue: -2,
    },
    {
      predicted: true,
      value: -5,
      date: "2021-11-06T05:00:00.000Z",
      minValue: -7,
      maxValue: -3,
    },
    {
      predicted: true,
      value: -5,
      date: "2021-11-05T23:00:00.000Z",
      minValue: -8,
      maxValue: -4,
    },
    {
      predicted: true,
      value: -7,
      date: "2021-11-05T17:00:00.000Z",
      minValue: -9,
      maxValue: -6,
    },
    {
      predicted: true,
      value: -7,
      date: "2021-11-05T11:00:00.000Z",
      minValue: -9,
      maxValue: -5,
    },
    {
      predicted: true,
      value: -3,
      date: "2021-11-05T05:00:00.000Z",
      minValue: -6,
      maxValue: -2,
    },
    {
      predicted: true,
      value: -2,
      date: "2021-11-04T23:00:00.000Z",
      minValue: -4,
      maxValue: 0,
    },
    {
      predicted: true,
      value: -2,
      date: "2021-11-04T17:00:00.000Z",
      minValue: -5,
      maxValue: -1,
    },
    {
      predicted: true,
      value: 1,
      date: "2021-11-04T11:00:00.000Z",
      minValue: -1,
      maxValue: 2,
    },
    {
      value: 0,
      date: "2021-11-04T05:00:00.000Z",
    },
    {
      value: 3,
      date: "2021-11-03T23:00:00.000Z",
    },
    {
      value: 1,
      date: "2021-11-03T17:00:00.000Z",
    },
    {
      value: 2,
      date: "2021-11-03T11:00:00.000Z",
    },
    {
      value: 2,
      date: "2021-11-03T05:00:00.000Z",
    },
    {
      value: 1,
      date: "2021-11-02T23:00:00.000Z",
    },
    {
      value: 1,
      date: "2021-11-02T17:00:00.000Z",
    },
    {
      value: -1,
      date: "2021-11-02T11:00:00.000Z",
    },
    {
      value: -5,
      date: "2021-11-02T05:00:00.000Z",
    },
    {
      value: -8,
      date: "2021-11-01T23:00:00.000Z",
    },
    {
      value: -5,
      date: "2021-11-01T17:00:00.000Z",
    },
    {
      value: -9,
      date: "2021-11-01T11:00:00.000Z",
    },
    {
      value: -7,
      date: "2021-11-01T05:00:00.000Z",
    },
    {
      value: -3,
      date: "2021-10-31T23:00:00.000Z",
    },
    {
      value: 1,
      date: "2021-10-31T17:00:00.000Z",
    },
    {
      value: -3,
      date: "2021-10-31T11:00:00.000Z",
    },
    {
      value: -1,
      date: "2021-10-31T05:00:00.000Z",
    },
    {
      value: -2,
      date: "2021-10-30T22:00:00.000Z",
    },
    {
      value: -2,
      date: "2021-10-30T16:00:00.000Z",
    },
    {
      value: 0,
      date: "2021-10-30T10:00:00.000Z",
    },
    {
      value: -2,
      date: "2021-10-30T04:00:00.000Z",
    },
    {
      value: -6,
      date: "2021-10-29T22:00:00.000Z",
    },
    {
      value: -7,
      date: "2021-10-29T16:00:00.000Z",
    },
    {
      value: -5,
      date: "2021-10-29T10:00:00.000Z",
    },
    {
      value: -5,
      date: "2021-10-29T04:00:00.000Z",
    },
    {
      value: -6,
      date: "2021-10-28T22:00:00.000Z",
    },
    {
      value: -5,
      date: "2021-10-28T16:00:00.000Z",
    },
    {
      value: -3,
      date: "2021-10-28T10:00:00.000Z",
    },
    {
      value: 1,
      date: "2021-10-28T04:00:00.000Z",
    },
    {
      value: 2,
      date: "2021-10-27T22:00:00.000Z",
    },
    {
      value: -1,
      date: "2021-10-27T16:00:00.000Z",
    },
    {
      value: -2,
      date: "2021-10-27T10:00:00.000Z",
    },
    {
      value: -5,
      date: "2021-10-27T04:00:00.000Z",
    },
    {
      value: -5,
      date: "2021-10-26T22:00:00.000Z",
    },
    {
      value: -9,
      date: "2021-10-26T16:00:00.000Z",
    },
    {
      value: -7,
      date: "2021-10-26T10:00:00.000Z",
    },
    {
      value: -10,
      date: "2021-10-26T04:00:00.000Z",
    },
    {
      value: -10,
      date: "2021-10-25T22:00:00.000Z",
    },
    {
      value: -9,
      date: "2021-10-25T16:00:00.000Z",
    },
    {
      value: -9,
      date: "2021-10-25T10:00:00.000Z",
    },
    {
      value: -5,
      date: "2021-10-25T04:00:00.000Z",
    },
  ];

  const formatedData = lineData.map(({ date, ...rest }) => ({
    ...rest,
    date: DateTime.fromISO(date).toJSDate(),
  }));

  const series = [
    {
      key: "singleLineFuture",
      name: "Water Level",
      unit: "°C",
      labelWidth: 46,
      data: formatedData,
      threshold: 0,
      thresholdColor: "#00f",
      thresholdDirection: "down",
    },
  ];

  const Sidebar = () => {
    return (
      <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom} | Bearing: {bearing} |
        Pitch: {pitch}
      </div>
    );
  };

  const Chart = () => {
    return (
      <div className="chart">
        <Graph entryWidth="100" height="4" series={series} t={(t) => t} />
      </div>
    );
  };

  return (
    <div className="container">
      <div className="map">
        <img className="logo" src={logo} alt="Intoto Beta" />
        <div ref={mapContainer} className="map-container" />
      </div>
    </div>
  );
};

export default App;
