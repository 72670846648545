const RIVER_LINES = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [
          [6.933478116989136, 58.69886160455949],
          [6.933043599128723, 58.69857732850594],
          [6.933826804161072, 58.69791400869335],
          [6.934137940406799, 58.697454136704486],
          [6.935060620307922, 58.697423478356036],
          [6.935634613037109, 58.69659290556053],
          [6.936739683151244, 58.69596299486898],
          [6.937501430511474, 58.69576788747757],
          [6.937522888183593, 58.69337076464421],
          [6.936814785003662, 58.69003681734088],
          [6.936235427856445, 58.68955732711304],
          [6.9355058670043945, 58.68967998803182],
          [6.9327592849731445, 58.68985282768464],
          [6.930559873580933, 58.68981937491553],
          [6.929336786270142, 58.68939563706046],
          [6.9284141063690186, 58.689323155069246],
          [6.927298307418823, 58.688871533112774],
          [6.927362680435181, 58.68756123876102],
          [6.926579475402832, 58.68707613857043],
          [6.92510962486267, 58.68643490381971],
          [6.919616460800171, 58.6848512822419],
          [6.915915012359619, 58.6839144581432],
          [6.9154322147369385, 58.682726662820656],
          [6.91564679145813, 58.68207977134982],
          [6.915775537490845, 58.6814384446802],
          [6.916269063949584, 58.6809811436754],
          [6.917170286178589, 58.68040114352848],
          [6.918704509735107, 58.67958689627159],
          [6.920024156570435, 58.679257845751984],
          [6.921515464782715, 58.6779527673445],
          [6.921579837799072, 58.67683727699397],
          [6.921955347061157, 58.67630182894954],
          [6.9234466552734375, 58.675498641465666],
          [6.923907995223999, 58.67527553054795],
          [6.925013065338135, 58.673685823938065],
          [6.925592422485352, 58.67164977805158],
          [6.924712657928467, 58.67024957888704],
          [6.926697492599487, 58.66916173595382],
          [6.928285360336304, 58.66715890048559],
          [6.930989027023315, 58.66623833864442],
          [6.932662725448608, 58.66613233299378],
          [6.934411525726318, 58.66561903791847],
          [6.936718225479126, 58.66539028441925],
          [6.93760871887207, 58.665535347787895],
          [6.940494775772095, 58.66557440320715],
          [6.941846609115601, 58.66554650648357],
          [6.9428551197052, 58.66517826764152],
          [6.943541765213013, 58.664369244413095],
          [6.943284273147583, 58.66353230408783],
          [6.942318677902222, 58.662751141668444],
          [6.943386197090149, 58.661677014781645],
          [6.9426029920578, 58.660722827577246],
          [6.943402290344238, 58.65941147510205],
          [6.9480156898498535, 58.65994439237188],
          [6.948959827423096, 58.66034616754975],
          [6.951491832733154, 58.660580534267],
          [6.952800750732422, 58.65994439237188],
          [6.951127052307128, 58.658437694249564],
          [6.94760799407959, 58.65718764346503],
          [6.943316459655761, 58.65333675916367],
          [6.942200660705566, 58.64794480721659],
          [6.9458270072937, 58.64647108535897],
          [6.946181058883667, 58.64558347285722],
          [6.943992376327515, 58.64471258582771],
          [6.938166618347168, 58.64397566444956],
          [6.933832168579102, 58.642579937393826],
          [6.934390068054199, 58.63865485385867],
          [6.929068565368652, 58.63838683775334],
          [6.928339004516602, 58.632110205871875],
          [6.92662239074707, 58.630211358348326],
          [6.9251203536987305, 58.630077318152225],
          [6.920657157897949, 58.62757514008142],
          [6.913876533508301, 58.62527387169083],
          [6.91190242767334, 58.62426841559102],
          [6.91340446472168, 58.62245852169643],
          [6.91014289855957, 58.61836915698654],
          [6.910400390625, 58.61671539821604],
          [6.910228729248046, 58.61550855188757],
          [6.909799575805664, 58.614502814781126],
          [6.909928321838379, 58.61396640982473],
          [6.910228729248046, 58.61363115254678],
          [6.90914511680603, 58.61312267287181],
          [6.908801794052124, 58.61284328528643],
          [6.908619403839111, 58.61265330045288],
          [6.907181739807129, 58.61164748118929],
          [6.9071173667907715, 58.61139043384559],
          [6.906548738479613, 58.6110719160367],
          [6.905250549316406, 58.61069751419989],
          [6.902246475219727, 58.609719580039844],
          [6.9031476974487305, 58.608311306789616],
          [6.901988983154297, 58.60598641413145],
          [6.901731491088867, 58.60437678249878],
          [6.898941993713379, 58.60276707676774],
          [6.901516914367676, 58.6012467310931],
          [6.901946067810058, 58.60062068719189],
          [6.906280517578124, 58.60140324031734],
          [6.908855438232422, 58.6010007866113],
          [6.90615177154541, 58.595925000701286],
          [6.903308629989624, 58.59368873793316],
          [6.901388168334961, 58.592710328016935],
          [6.899435520172119, 58.59156974705778],
          [6.898711323738098, 58.59077579130176],
          [6.896528005599976, 58.58926890318814],
          [6.89617395401001, 58.588430161837735],
          [6.897075176239013, 58.588340694907],
          [6.900186538696289, 58.588567157632106],
          [6.900744438171387, 58.588030354717894],
          [6.895852088928223, 58.58407117888296],
          [6.895380020141602, 58.58299742686768],
          [6.888256072998046, 58.57648709995409],
          [6.894092559814453, 58.572884646166756],
          [6.897397041320801, 58.570467885570096],
          [6.900057792663574, 58.56869996430453],
          [6.90065860748291, 58.56890137756164],
          [6.900830268859863, 58.569818912183734],
          [6.901602745056152, 58.57004269746878],
          [6.90615177154541, 58.569595125468425],
          [6.90615177154541, 58.565589101323624],
          [6.908125877380371, 58.56538766901466],
          [6.9094133377075195, 58.565007182603274],
          [6.909284591674804, 58.56435810800792],
          [6.908769607543944, 58.55873975282141],
          [6.9094133377075195, 58.5581801063393],
          [6.908555030822754, 58.556702596666874],
          [6.908383369445801, 58.55533696413162],
          [6.90739631652832, 58.55291899236228],
          [6.906752586364746, 58.55218013437044],
          [6.9055938720703125, 58.55117257563633],
          [6.903533935546875, 58.54620152851296],
          [6.9011735916137695, 58.54199126148168],
          [6.903362274169921, 58.54167771072473],
          [6.903319358825684, 58.53681731533066],
          [6.905035972595215, 58.53578691453466],
          [6.903920173645019, 58.53533890474319],
          [6.904563903808594, 58.533748423750446],
          [6.9065165519714355, 58.5324267015612],
          [6.906580924987793, 58.53181062758622],
          [6.9084906578063965, 58.53200105160691],
          [6.909842491149902, 58.53207946119725],
          [6.915678977966309, 58.53202345436489],
          [6.916344165802002, 58.52992873460159],
          [6.913812160491943, 58.525839749994724],
          [6.91439151763916, 58.525111524646064],
          [6.914198398590088, 58.52236653936041],
          [6.913919448852539, 58.52115643681459],
          [6.91464900970459, 58.51935241015302],
          [6.915292739868164, 58.51772758624739],
          [6.916000843048096, 58.516326815589075],
          [6.91464900970459, 58.51527339921501],
          [6.912503242492676, 58.51442167774895],
          [6.912417411804199, 58.51346907003989],
          [6.9147562980651855, 58.512987152760594],
          [6.917095184326172, 58.51003946816396],
          [6.914370059967041, 58.508705643586936],
          [6.9133830070495605, 58.50783134451399],
          [6.913704872131348, 58.50363888998185],
          [6.915700435638428, 58.50319046948228],
          [6.916515827178954, 58.502753253980366],
          [6.916623115539551, 58.50039892306457],
          [6.9191765785217285, 58.50051103764058],
          [6.921343803405762, 58.499647746170574],
          [6.924540996551514, 58.497349269292556],
          [6.9210004806518555, 58.49537582276358],
          [6.921708583831787, 58.49409750844207],
          [6.923768520355225, 58.4929649279315],
          [6.924262046813965, 58.491137022939704],
          [6.924991607666016, 58.490565082206494],
          [6.925313472747802, 58.4907669447052],
          [6.9278883934021, 58.49075573015239],
          [6.929540634155273, 58.49141738264259],
          [6.928296089172363, 58.4879295486936],
          [6.930978298187256, 58.48649394086698],
          [6.934518814086914, 58.48454231739162],
          [6.933942139148712, 58.48323276212495],
          [6.934030652046204, 58.48274061471101],
          [6.934990882873535, 58.48156420022378],
          [6.935677528381348, 58.48109306251792],
          [6.9342827796936035, 58.479640348155755],
          [6.931740045547485, 58.4798310553963],
          [6.930570602416992, 58.47886629049459],
          [6.930066347122192, 58.47836707034983],
          [6.9288647174835205, 58.478294149959794],
          [6.928178071975708, 58.47711618423228],
          [6.927802562713622, 58.475006966000635],
          [6.926536560058594, 58.4731444659424],
          [6.925806999206542, 58.4702607621247],
          [6.922502517700195, 58.46600770883077],
          [6.923983097076416, 58.46170924569835],
          [6.922266483306885, 58.457376581434794],
          [6.920206546783447, 58.4537394252464],
          [6.9194555282592765, 58.450259078024104],
          [6.918210983276367, 58.44737349683485],
          [6.916301250457764, 58.444768410938686],
          [6.919970512390136, 58.44034381450157],
          [6.920421123504638, 58.43450339392017],
          [6.922309398651123, 58.429538274411804],
          [6.924326419830321, 58.42463986861659],
          [6.925442218780518, 58.41984191618833],
          [6.928145885467529, 58.41439145835171],
          [6.92713737487793, 58.41236839343149],
          [6.928811073303223, 58.4086928615929],
          [6.931343078613281, 58.40810833801483],
          [6.933445930480957, 58.40538792764206],
          [6.933317184448242, 58.40191404051896],
          [6.935977935791016, 58.40118324397338],
          [6.939818859100342, 58.401824097146424],
          [6.941792964935303, 58.401408106062796],
          [6.942179203033447, 58.397765163589995],
          [6.942415237426757, 58.39679814649347],
          [6.94383144378662, 58.396258404486396],
          [6.945397853851318, 58.396123467693435],
          [6.947200298309326, 58.39644956405897],
          [6.948187351226806, 58.39682063556442],
          [6.9499897956848145, 58.395932306352606],
          [6.949968338012695, 58.39490901331495],
          [6.952264308929443, 58.39242374942259],
          [6.952199935913086, 58.38743020101856],
          [6.953122615814208, 58.3868228293314],
          [6.954710483551025, 58.38277341742206],
          [6.952006816864014, 58.38230095574744],
          [6.951384544372559, 58.3817272437775],
          [6.957435607910156, 58.376777184338955],
          [6.955611705780029, 58.37396434111462],
          [6.952972412109375, 58.37205147962843],
          [6.952672004699707, 58.370464851134685],
          [6.955697536468506, 58.36650359488868],
          [6.956727504730225, 58.365569483890475],
          [6.957135200500488, 58.36496173574996],
          [6.958873271942139, 58.36398256395698],
          [6.9606757164001465, 58.36349859545421],
          [6.961834430694579, 58.36340855407041],
          [6.964259147644043, 58.363960053941206],
          [6.967005729675293, 58.361663956924396],
          [6.970460414886475, 58.35819701766832],
          [6.968185901641845, 58.35488734894926],
          [6.967349052429199, 58.35359266623088],
          [6.9663190841674805, 58.35278205810455],
          [6.96558952331543, 58.35198269020277],
          [6.964559555053711, 58.35117204511959],
          [6.963036060333252, 58.35067664174167],
          [6.963014602661133, 58.35028256591033],
          [6.962027549743652, 58.34959573752258],
          [6.961963176727295, 58.349145351000885],
          [6.962156295776367, 58.3487625179417],
          [6.9629716873168945, 58.348312120794645],
          [6.965138912200928, 58.347355007786845],
          [6.966147422790527, 58.34701719700355],
          [6.963143348693848, 58.34542944304524],
          [6.9620490074157715, 58.34456234160496],
          [6.960182189941406, 58.34375152619662],
          [6.9593024253845215, 58.34274924254087],
          [6.960010528564453, 58.3404067159159],
          [6.959323883056641, 58.33937054882597],
          [6.958658695220947, 58.33848077240053],
          [6.958508491516113, 58.33729812348169],
          [6.958572864532471, 58.33554097199464],
          [6.959216594696045, 58.33386258546362],
          [6.958465576171874, 58.332184119217565],
          [6.958208084106445, 58.33178983706086],
          [6.95734977722168, 58.331271630965354],
          [6.956748962402344, 58.33038165059362],
          [6.95634126663208, 58.329683169289964],
          [6.957263946533203, 58.328872012389155],
          [6.957306861877441, 58.327610175764725],
          [6.958658695220947, 58.32701304094626],
          [6.959366798400879, 58.32610041918793],
          [6.959624290466309, 58.325424387880304],
          [6.958272457122803, 58.324590598129404],
          [6.95958137512207, 58.323644110256694],
          [6.960783004760742, 58.3226074516071],
          [6.958744525909424, 58.32184120611197],
          [6.956427097320557, 58.32098479443606],
          [6.956233978271484, 58.319271908851924],
          [6.9561052322387695, 58.31742370239489],
          [6.957907676696777, 58.316826395508265],
          [6.960010528564453, 58.31615018685703],
          [6.961469650268555, 58.31444833786657],
          [6.960439682006835, 58.31326488466545],
          [6.959774494171143, 58.312690050250666],
          [6.962735652923584, 58.3094324787589],
          [6.962842941284179, 58.30715538213994],
          // [
          //   6.957414150238037,
          //   58.30362672012483
          // ],
          // [
          //   6.951470375061035,
          //   58.30014280759585
          // ],
          // [
          //   6.946406364440918,
          //   58.2977185236858
          // ],
          // [
          //   6.940827369689941,
          //   58.296128554296786
          // ],
          // [
          //   6.935334205627441,
          //   58.29478660965471
          // ],
          // [
          //   6.931257247924805,
          //   58.293591221159936
          // ],
          // [
          //   6.930785179138184,
          //   58.29228301390038
          // ],
          // [
          //   6.930484771728516,
          //   58.29000481343735
          // ],
          // [
          //   6.928296089172363,
          //   58.28734296777074
          // ],
          // [
          //   6.919369697570801,
          //   58.28454555835006
          // ],
          // [
          //   6.911859512329102,
          //   58.2831016476547
          // ],
          // [
          //   6.9049072265625,
          //   58.280980797070455
          // ],
          // [
          //   6.899843215942383,
          //   58.277663893106606
          // ],
          // [
          //   6.893405914306641,
          //   58.27351168436212
          // ],
          // [
          //   6.888427734375,
          //   58.27195448059009
          // ],
        ],
      },
    },
  ],
};

export { RIVER_LINES };
