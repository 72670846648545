const ELEVATION_STYLE = 'mapbox://mapbox.terrain-rgb';

const Helpers = {

  addFog(map) {
    map.setFog({
      'range': [0, 2],
      'color': 'white',
      'horizon-blend': 0.2
    });
  },

  addSky(map) {
    map.addLayer({
      'id': 'sky',
      'type': 'sky',
      'paint': {
        'sky-type': 'atmosphere',
        'sky-atmosphere-color': 'rgba(85, 151, 210, 0.5)'
      }
    });
  },

  addElevation(map) {
    map.addSource('mapbox-dem', {
      'type': 'raster-dem',
      'url': ELEVATION_STYLE,
      'tileSize': 512,
      'maxzoom': 14
    });

    map.setTerrain({
      'source': 'mapbox-dem',
      'exaggeration': 1.5
    });
  },
};

export { Helpers };
